<template>
  <!--begin::Card-->
  <div class="card card-custom animate__animated animate__fadeIn">
    <!--begin::Header-->
    <div class="card-header py-3">
      <div class="card-title align-items-start flex-column">
        <h3 class="card-label font-weight-bolder text-dark">Ganti Password</h3>
        <span class="text-muted font-weight-bold font-size-sm mt-1"
          >Change your account password</span
        >
      </div>
      <div class="card-toolbar">
        <button
          type="submit"
          class="btn btn-success mr-2"
          @click="formOnSubmit()"
          ref="kt_save_changes"
        >
          Simpan
        </button>
        <!-- <button type="reset" class="btn btn-secondary" @click="cancel()">
          Cancel
        </button> -->
      </div>
    </div>
    <!--end::Header-->
    <!--begin::Form-->
    <form class="form" id="kt_password_change_form">
      <div class="card-body">
        <!--begin::Alert-->
        <!-- <div
          class="alert alert-custom alert-light-danger fade show mb-10"
          role="alert"
        >
          <div class="alert-icon">
            <span class="svg-icon svg-icon-3x svg-icon-danger">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                xmlns:xlink="http://www.w3.org/1999/xlink"
                width="24px"
                height="24px"
                viewBox="0 0 24 24"
                version="1.1"
              >
                <g
                  stroke="none"
                  stroke-width="1"
                  fill="none"
                  fill-rule="evenodd"
                >
                  <rect x="0" y="0" width="24" height="24" />
                  <circle fill="#000000" opacity="0.3" cx="12" cy="12" r="10" />
                  <rect
                    fill="#000000"
                    x="11"
                    y="10"
                    width="2"
                    height="7"
                    rx="1"
                  />
                  <rect
                    fill="#000000"
                    x="11"
                    y="7"
                    width="2"
                    height="2"
                    rx="1"
                  />
                </g>
              </svg>
            </span>
          </div>
          <div class="alert-text font-weight-bold">
            Configure user passwords to expire periodically. Users will need
            warning that their passwords are going to expire, <br />or they
            might inadvertently get locked out of the system!
          </div>
          <div class="alert-close">
            <button
              type="button"
              class="close"
              data-dismiss="alert"
              aria-label="Close"
            >
              <span aria-hidden="true">
                <i class="ki ki-close"></i>
              </span>
            </button>
          </div>
        </div> -->
        <!--end::Alert-->
        <div class="form-group row">
          <label class="col-xl-3 col-lg-3 col-form-label text-alert"
            >Password Lama</label
          >
          <div class="col-lg-9 col-xl-6">
            <div class="input-group input-group-md">
              <div class="input-group-prepend">
                <span class="input-group-text" @click="oldPasswordIsVisible == 'password' ? oldPasswordIsVisible = 'text' : oldPasswordIsVisible = 'password' ">
                  <div v-if="oldPasswordIsVisible == 'password'">
                      <b-icon-eye-slash></b-icon-eye-slash>
                    </div>
                    <div v-if="oldPasswordIsVisible == 'text'">
                      <b-icon-eye></b-icon-eye>
                    </div>
                </span>
              </div>
              <input
                :type="oldPasswordIsVisible"
                class="form-control form-control-md"
                placeholder="Password Lama"
                v-model="form.old_password"
              />
            </div>
            <small class="text-danger">{{ error.old_password }}</small>
          </div>
        </div>
        <div class="form-group row">
          <label class="col-xl-3 col-lg-3 col-form-label text-alert"
            >Password Baru</label
          >
          <div class="col-lg-9 col-xl-6">
            <div class="input-group input-group-md">
              <div class="input-group-prepend">
                <span class="input-group-text" @click="passwordIsVisible == 'password' ? passwordIsVisible = 'text' : passwordIsVisible = 'password' ">
                  <div v-if="passwordIsVisible == 'password'">
                      <b-icon-eye-slash></b-icon-eye-slash>
                    </div>
                    <div v-if="passwordIsVisible == 'text'">
                      <b-icon-eye></b-icon-eye>
                    </div>
                </span>
              </div>
              <input
                :type="passwordIsVisible"
                class="form-control form-control-md"
                placeholder="Password Baru"
                v-model="form.new_password"
              />
            </div>
            <small class="text-danger">{{ error.new_password }}</small>
          </div>
        </div>
        <div class="form-group row">
          <label class="col-xl-3 col-lg-3 col-form-label text-alert"
            >Konfirmasi Password</label
          >
          <div class="col-lg-9 col-xl-6">
            <div class="input-group input-group-md">
              <div class="input-group-prepend">
                <span class="input-group-text" @click="passwordConfirmIsVisible == 'password' ? passwordConfirmIsVisible = 'text' : passwordConfirmIsVisible = 'password' ">
                  <div v-if="passwordConfirmIsVisible == 'password'">
                      <b-icon-eye-slash></b-icon-eye-slash>
                    </div>
                    <div v-if="passwordConfirmIsVisible == 'text'">
                      <b-icon-eye></b-icon-eye>
                    </div>
                </span>
              </div>
              <input
                :type="passwordConfirmIsVisible"
                class="form-control form-control-md"
                placeholder="Konfirmasi Password"
                v-model="form.new_password_confirmation"
              />
            </div>
            <small class="text-danger">{{ error.new_password_confirmation }}</small>
          </div>
        </div>
      </div>
    </form>
    <!--end::Form-->
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { UPDATE_PASSWORD } from "@/core/services/store/auth.module";
import KTUtil from "@/assets/js/components/util";

import formValidation from "@/assets/plugins/formvalidation/dist/es6/core/Core";

// FormValidation plugins
import Trigger from "@/assets/plugins/formvalidation/dist/es6/plugins/Trigger";
import Bootstrap from "@/assets/plugins/formvalidation/dist/es6/plugins/Bootstrap";
import SubmitButton from "@/assets/plugins/formvalidation/dist/es6/plugins/SubmitButton";
import Swal from "sweetalert2";
import { getUser } from "@/core/services/jwt.service.js";
import module from "@/core/modules/CrudModule.js";
import validation from "@/core/modules/ValidationModule.js";

export default {
  name: "ChangePassword",
  data() {
    return {
      password: "",
      status: "",
      valid: true,
      // Form
      form: {
        old_password: '',
        new_password: '',
        new_password_confirmation: '',
        '_method': 'put'
      },
      // Error
      error: {
        old_password: '',
        new_password: '',
        new_password_confirmation: ''
      },
      // Other
      oldPasswordIsVisible: 'password',
      passwordIsVisible: 'password',
      passwordConfirmIsVisible: 'password',
    };
  },
  mounted() {
    const password_change_form = KTUtil.getById("kt_password_change_form");
    var curr_password = this.currentUser.password;

    this.fv = formValidation(password_change_form, {
      fields: {
        current_password: {
          validators: {
            notEmpty: {
              message: "Current password is required"
            },
            identical: {
              compare: function() {
                return curr_password;
              },
              message: "Wrong password"
            }
          }
        },
        new_password: {
          validators: {
            notEmpty: {
              message: "New password is required"
            }
          }
        },
        verify_password: {
          validators: {
            notEmpty: {
              message: "Confirm password is required"
            },
            identical: {
              compare: function() {
                return password_change_form.querySelector(
                  '[name="new_password"]'
                ).value;
              },
              message: "The password and its confirm are not the same"
            }
          }
        }
      },
      plugins: {
        trigger: new Trigger(),
        bootstrap: new Bootstrap(),
        submitButton: new SubmitButton()
      }
    });
  },
  methods: {
    save() {
      this.fv.validate();

      this.fv.on("core.form.valid", () => {
        var password = this.$refs.new_password.value;
        const submitButton = this.$refs["kt_save_changes"];

        // set spinner to submit button
        submitButton.classList.add("spinner", "spinner-light", "spinner-right");

        // dummy delay
        setTimeout(() => {
          // send update request
          this.$store
            .dispatch(UPDATE_PASSWORD, { password })
            // go to which page after successfully update
            .then(() => this.$router.push({ name: "dashboard" }));

          submitButton.classList.remove(
            "spinner",
            "spinner-light",
            "spinner-right"
          );
        }, 2000);
      });

      this.fv.on("core.form.invalid", () => {
        Swal.fire({
          title: "",
          text: "Please, provide correct data!",
          icon: "error",
          confirmButtonClass: "btn btn-secondary"
        });
      });
    },
    cancel() {
      this.fv.resetForm();
      this.$refs.current_password.value = "";
      this.$refs.new_password.value = "";
      this.$refs.verify_password.value = "";
    },

    async formOnSubmit() {
      // Make Request
      let response = await module.submit(this.form, 'api/profile/change-password/' + getUser().id)
      // Check Response
      if (response.state == 'error') {
        // Validation Error
        this.error = validation.setValidationError(this.error, response.error.errors)
      } else {
        // Success
        Swal.fire(response.success.title, response.success.message, 'success')
        // let self = this.
        // Object.keys(this.form).forEach(function (key, index) {
        //   self.form[key] = "";
        // });
        this.form.new_password = ''
        this.form.old_password = ''
        this.new_password_confirmation = ''
        // this.$router.push('/profile/show')
      }
    }
  },
  computed: {
    ...mapGetters(["currentUser"])
  }
};
</script>

<style scoped></style>
