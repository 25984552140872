<template>
<v-app>
  <!--begin::Card-->
  <div class="card card-custom animate__animated animate__fadeIn">
    <!--begin::Header-->
    <div class="card-header py-3">
      <div class="card-title align-items-start flex-column">
        <h3 class="card-label font-weight-bolder text-dark">
          Informasi Pribadi
        </h3>
        <span class="text-muted font-weight-bold font-size-sm mt-1"
          >Perbarui informasi pribadi kamu</span
        >
      </div>
      <!-- <div class="card-toolbar">
        <button
          type="reset"
          class="btn btn-success mr-2"
          @click="formOnsubmit()"
          ref="kt_save_changes"
        >
          Simpan
        </button>
        <button type="reset" class="btn btn-secondary" @click="cancel()">
          Cancel
        </button>
      </div> -->
    </div>
    <!--end::Header-->
    <!--begin::Form-->
    <form class="form">
      <!--begin::Body-->
      <div class="card-body">
        <div class="row">
          <label class="col-xl-3 py-0"></label>
          <div class="col-lg-9 col-xl-6 py-0">
            <h5 class="font-weight-bold">Info Pengguna</h5>
          </div>
        </div>
        <div class="form-group row">
          <label class="col-xl-3 col-lg-3 col-form-label text-right"
            >Gambar</label
          >
          <div class="col-lg-9 col-xl-6">
            <div class="image-input image-input-outline" id="kt_profile_avatar">
              <div class="image-input-wrapper">
                <img :src="photo" alt="" />
              </div>
              <!-- <div
                class="image-input-wrapper"
                :style="{ backgroundImage: `url(${photo})` }"
              ></div> -->
              <label
                class="
                  btn
                  btn-xs
                  btn-icon
                  btn-circle
                  btn-white
                  btn-hover-text-primary
                  btn-shadow
                "
                data-action="change"
                data-toggle="tooltip"
                title=""
                data-original-title="Change avatar"
              >
                <i class="fa fa-pen icon-sm text-muted"></i>
                <input
                  type="file"
                  name="profile_avatar"
                  accept=".png, .jpg, .jpeg"
                  @change="onFileChange($event)"
                />
                <input type="hidden" name="profile_avatar_remove" />
              </label>
              <span
                class="
                  btn
                  btn-xs
                  btn-icon
                  btn-circle
                  btn-white
                  btn-hover-text-primary
                  btn-shadow
                "
                data-action="cancel"
                data-toggle="tooltip"
                title="Cancel avatar"
              >
                <i class="ki ki-bold-close icon-xs text-muted"></i>
              </span>
              <span
                class="
                  btn
                  btn-xs
                  btn-icon
                  btn-circle
                  btn-white
                  btn-hover-text-primary
                  btn-shadow
                "
                data-action="remove"
                data-toggle="tooltip"
                title="Remove avatar"
                @click="current_photo = null"
              >
                <i class="ki ki-bold-close icon-xs text-muted"></i>
              </span>
            </div>
            <span class="form-text text-muted"
              >Jenis file yang diizinkan: png, jpg, jpeg.</span
            >
          </div>
        </div>
        <!-- <div class="form-group row">
          <label class="col-xl-3 col-lg-3 col-form-label text-right"
            >NIP/NIK</label
          >
          <div class="col-lg-9 col-xl-6">
            <input
              ref="name"
              class="form-control form-control-md"
              type="text"
              v-model="form.registration_number"
            />
            <small class="text-danger">{{ error.registration_number }}</small>
          </div>
        </div> -->
        <div class="form-group row">
          <label class="col-xl-3 col-lg-3 col-form-label text-right"
            >Nama Lengkap</label
          >
          <div class="col-lg-9 col-xl-6">
            <input
              ref="name"
              class="form-control form-control-md"
              type="text"
              v-model="form.name"
            />
            <small class="text-danger">{{ error.name }}</small>
          </div>
        </div>
        <div class="form-group row">
          <label
            class="col-xl-3 col-lg-3 col-form-label text-right"
            v-if="currentUser.role_key_name == 'student'"
            >NIM</label
          >
          <label
            class="col-xl-3 col-lg-3 col-form-label text-right"
            v-if="currentUser.role_key_name != 'student'"
            >NIP</label
          >
          <div class="col-lg-9 col-xl-6">
            <input
              ref="registration_number"
              class="form-control form-control-md"
              type="text"
              readonly
              v-model="form.registration_number"
            />
            <small class="text-danger">{{ error.registration_number }}</small>
          </div>
        </div>
        <div class="form-group row">
          <label
            class="col-xl-3 col-lg-3 col-form-label text-right"
            >Tempat & Tgl. Lahir</label
          >
          <div class="col-lg-9 col-xl-6">
            <b-input-group>
            <b-form-input
              id="input-birth-place"
              aria-label="Tempat Lahir"
              placeholder="Tempat Lahir"
              v-model="form.birt_place"
            ></b-form-input>

            <v-dialog
              ref="dialog"
              v-model="modal2"
              :return-value.sync="form.birt_date"
              persistent
              width="350px"
            >
              <template v-slot:activator="{ on, attrs }">
                <b-form-input
                  readonly
                  v-bind="attrs"
                  v-on="on"
                  id="input-trigger-modal-patient"
                  v-model="form.birt_date"
                  placeholder="Tanggal Lahir"
                >
                </b-form-input>
              </template>

              <v-date-picker v-if="modal2" v-model="form.birt_date" locale="id">
                <v-spacer></v-spacer>
                <v-btn text color="primary" @click="modal2 = false">
                  Cancel
                </v-btn>
                <v-btn
                  text
                  color="primary"
                  @click="$refs.dialog.save(form.birt_date)"
                >
                  OK
                </v-btn>
              </v-date-picker>
            </v-dialog>
          </b-input-group>
          <div class="row">
            <div class="col">
              <small class="text-danger">{{ error.birt_place }}</small>
            </div>
            <div class="col">
              <small class="text-danger">{{ error.birt_date }}</small>
            </div>
          </div>
          </div>
        </div>
        <div class="form-group row">
          <label class="col-xl-3 col-lg-3 pb-0 col-form-label text-right"
            >Alamat Rumah</label
          >
          <div class="col-lg-9 col-xl-6 pb-0">
            <b-form-textarea
              id="input-address"
              v-model="form.address"
              class="form-control form-control-md"
              placeholder="Alamat Rumah"
              rows="4"
              max-rows="8"
            ></b-form-textarea>
            <small class="text-danger">{{ error.address }}</small>
          </div>
        </div>
        <div
          class="form-group row"
        >
          <label
            class="col-xl-3 col-lg-3 col-form-label text-right"
          >
            Alamat Email
          </label>
          <div class="col-lg-9 col-xl-6">
            <input
              ref="email2"
              class="form-control form-control-md"
              type="text"
              v-model="form.email2"
            />
            <small class="text-danger">{{ error.email2 }}</small>
          </div>
        </div>
        <div
          class="form-group row"
        >
          <label
            class="col-xl-3 col-lg-3 col-form-label text-right"
          >
            No. Wa Aktif
          </label>
          <div class="col-lg-9 col-xl-6">
            <input
              ref="phone"
              class="form-control form-control-md"
              type="text"
              v-model="form.phone"
            />
            <small class="text-danger">{{ error.phone }}</small>
          </div>
        </div>
        <div
          class="form-group row"
          v-if="currentUser.role_key_name == 'student'"
        >
          <label class="col-xl-3 col-lg-3 pb-0 col-form-label text-right"
            >Nama Orang Tua</label
          >
          <div class="col-lg-9 col-xl-6 pb-0">
            <b-input-group>
              <b-form-input
                id="input-father-name"
                v-model="form.father_name"
                placeholder="Ayah"
              ></b-form-input>
              <b-form-input
                id="input-mother-name"
                v-model="form.mother_name"
                placeholder="Ibu"
              ></b-form-input>
            </b-input-group>
            <div class="row">
              <div class="col">
                <small class="text-danger">{{ error.father_name }}</small>
              </div>
              <div class="col">
                <small class="text-danger">{{ error.mother_name }}</small>
              </div>
            </div>
          </div>
        </div>
        <div
          class="form-group row"
          v-if="currentUser.role_key_name == 'student'"
        >
          <label
            class="col-xl-3 col-lg-3 col-form-label text-right"
          >
            Pekerjaan Orang Tua
          </label>
          <div class="col-lg-9 col-xl-6">
            <b-input-group>
              <b-form-input
                id="input-father-job"
                v-model="form.father_job"
                placeholder="Ayah"
              ></b-form-input>
              <b-form-input
                id="input-mother-job"
                v-model="form.mother_job"
                placeholder="Ibu"
              ></b-form-input>
            </b-input-group>
            <div class="row">
              <div class="col">
                <small class="text-danger">{{ error.father_job }}</small>
              </div>
              <div class="col">
                <small class="text-danger">{{ error.mother_job }}</small>
              </div>
            </div>
          </div>
        </div>

        <template v-if="currentUser.role_key_name == 'student'">
        <div
          class="form-group row"
          v-if="currentUser.major_id == 1 || currentUser.major_id == 2 || currentUser.major_id == 3"
        >
          <label
            class="col-xl-3 col-lg-3 col-form-label text-right"
          >
            Dosen Pembimbing Akademik
          </label>
          <div class="col-lg-9 col-xl-6">
            <treeselect
              v-model="form.academic_mentor"
              :multiple="false"
              placeholder="Pilih Dosen Pembimbing Akademik"
              :options="teachers"
            />
            <small class="text-danger">{{ error.academic_mentor }}</small>
          </div>
        </div>
        <div
          class="form-group row"
          v-if="currentUser.major_id == 1"
        >
          <label
            class="col-xl-3 col-lg-3 col-form-label text-right"
          >
            Dosen Pembimbing PKL
          </label>
          <div class="col-lg-9 col-xl-6">
            <treeselect
              v-model="form.pkl_mentor"
              :multiple="false"
              placeholder="Pilih Dosen Pembimbing PKL"
              :options="teachers"
            />
            <small class="text-danger">{{ error.pkl_mentor }}</small>
          </div>
        </div>
        <div
          class="form-group row"
          v-if="currentUser.major_id == 3"
        >
          <label
            class="col-xl-3 col-lg-3 col-form-label text-right"
          >
            Dosen Pembimbing PKM
          </label>
          <div class="col-lg-9 col-xl-6">
            <treeselect
              v-model="form.pkm_mentor"
              :multiple="false"
              placeholder="Pilih Dosen Pembimbing PKM"
              :options="teachers"
            />
            <small class="text-danger">{{ error.pkm_mentor }}</small>
          </div>
        </div>
        <div
          class="form-group row"
          v-if="currentUser.major_id == 1 || currentUser.major_id == 3"
        >
          <label
            class="col-xl-3 col-lg-3 col-form-label text-right"
          >
            Dosen Pembimbing 1 Skripsi
          </label>
          <div class="col-lg-9 col-xl-6">
            <treeselect
              v-model="form.essay_mentor_1"
              :multiple="false"
              placeholder="Pilih Dosen Pembimbing 1 Skripsi"
              :options="teachers"
            />
            <small class="text-danger">{{ error.essay_mentor_1 }}</small>
          </div>
        </div>
        <div
          class="form-group row"
          v-if="currentUser.major_id == 1 || currentUser.major_id == 3"
        >
          <label
            class="col-xl-3 col-lg-3 col-form-label text-right"
          >
            Dosen Pembimbing 2 Skripsi
          </label>
          <div class="col-lg-9 col-xl-6">
            <treeselect
              v-model="form.essay_mentor_2"
              :multiple="false"
              placeholder="Pilih Dosen Pembimbing 2 Skripsi"
              :options="teachers"
            />
            <small class="text-danger">{{ error.essay_mentor_2 }}</small>
          </div>
        </div>
        <div
          class="form-group row"
          v-if="currentUser.major_id == 2"
        >
          <label
            class="col-xl-3 col-lg-3 col-form-label text-right"
          >
            Dosen Pembimbing 2 Tesis
          </label>
          <div class="col-lg-9 col-xl-6">
            <treeselect
              v-model="form.thesis_mentor_1"
              :multiple="false"
              placeholder="Pilih Dosen Pembimbing 2 Tesis"
              :options="teachers"
            />
            <small class="text-danger">{{ error.thesis_mentor_1 }}</small>
          </div>
        </div>
        <div
          class="form-group row"
          v-if="currentUser.major_id == 2"
        >
          <label
            class="col-xl-3 col-lg-3 col-form-label text-right"
          >
            Dosen Pembimbing 2 Tesis
          </label>
          <div class="col-lg-9 col-xl-6">
            <treeselect
              v-model="form.thesis_mentor_2"
              :multiple="false"
              placeholder="Pilih Dosen Pembimbing 2 Tesis"
              :options="teachers"
            />
            <small class="text-danger">{{ error.thesis_mentor_2 }}</small>
          </div>
        </div>
        </template>

        <div
          class="form-group row"
          v-if="currentUser.role_key_name == 'teacher'"
        >
          <label
            class="col-xl-3 col-lg-3 col-form-label text-right"
            :class="currentUser.role_key_name == 'teacher' ? 'pb-0' : ''"
          >
            Nick Name
          </label>
          <div class="col-lg-9 col-xl-6">
            <input
              ref="nickname"
              class="form-control form-control-md"
              type="text"
              v-model="form.nickname"
            />
            <small class="text-danger">{{ error.nickname }}</small>
          </div>
        </div>
        <!-- <div class="row mt-0">
          <label class="col-xl-3 py-0"></label>
          <div class="col-lg-9 col-xl-6 py-0">
            <h5 class="font-weight-bold mt-10 mb-6">Info Kontak</h5>
          </div>
        </div> -->
        <!-- <div class="form-group row">
          <label class="col-xl-3 col-lg-3 pt-0 col-form-label text-right"
            >Email Address</label>
          <div class="col-lg-9 col-xl-6 pt-0">
            <div class="input-group input-group-md input-group-solid">
              <div class="input-group-prepend">
                <span class="input-group-text">
                  <i class="la la-at"></i>
                </span>
              </div>
              <input
                ref="email"
                type="text"
                class="form-control form-control-md"
                placeholder="Email"
                readonly
                v-bind:value="form.email"
              />
            </div>
          </div>
        </div> -->
        <!-- <div class="form-group row">
          <label class="col-xl-3 col-lg-3 col-form-label text-right"
            >No. Telepon</label
          >
          <div class="col-lg-9 col-xl-6">
            <div class="input-group input-group-md">
              <div class="input-group-prepend">
                <span class="input-group-text">
                  <i class="la la-phone"></i>
                </span>
              </div>
              <input
                ref="phone"
                type="text"
                class="form-control form-control-md"
                placeholder="Phone"
                v-model="form.phone"
              />
            </div>
            <small class="text-danger">{{ error.phone }}</small>
          </div>
        </div> -->
        <!-- <div class="row mt-0" v-if="currentUser.role_key_name == 'student'">
          <label class="col-xl-3 py-0"></label>
          <div class="col-lg-9 col-xl-6 py-0">
            <h5 class="font-weight-bold mt-10 mb-6">Info Akademik</h5>
          </div>
        </div> -->
        <!-- <div class="form-group row" v-if="currentUser.role_key_name == 'student'">
          <label class="col-xl-3 col-lg-3 pt-0 col-form-label text-right"
            >Tahun Ajaran</label
          >
          <div class="col-lg-9 col-xl-6 pt-0">
            <input
              ref="school_year_name"
              class="form-control form-control-md form-control-solid"
              type="text"
              v-model="form.school_year_name"
              readonly
            />
            <small class="text-danger">{{ error.school_year_name }}</small>
          </div>
        </div> -->
        <!-- <div class="form-group row" v-if="currentUser.role_key_name == 'student'">
          <label class="col-xl-3 col-lg-3 col-form-label text-right"
            >Prodi</label
          >
          <div class="col-lg-9 col-xl-6">
            <input
              ref="major_name"
              class="form-control form-control-md form-control-solid"
              type="text"
              v-model="form.major_name"
              readonly
            />
            <small class="text-danger">{{ error.major_name }}</small>
          </div>
        </div> -->
        <div class="card-toolbar">
          <button
            type="reset"
            class="btn btn-success ml-5 mt-5"
            @click="formOnsubmit()"
            ref="kt_save_changes"
          >
            Simpan
          </button>
        </div>
      </div>
      <!--end::Body-->
    </form>
    <!--end::Form-->
  </div>
  </v-app>
</template>

<script>
import { mapGetters } from "vuex";
import { UPDATE_PERSONAL_INFO } from "@/core/services/store/profile.module";
import module from "@/core/modules/CrudModule.js";
import validation from "@/core/modules/ValidationModule.js";
import { getUser, setUser } from "@/core/services/jwt.service.js";
import api from "@/core/services/api.service.js";
import "@riophae/vue-treeselect/dist/vue-treeselect.css";

export default {
  name: "PersonalInformation",
  data() {
    return {
      default_photo: "media/users/blank.png",
      current_photo: null,
      currentUser: getUser(),
      form: {},
      error: {
        address: "",
        email: "",
        grade_id: "",
        name: "",
        nickname: "",
        phone: "",
        photo: "",
        description: "",
        role_id: "",
        school_id: "",
        password: "",
        registration_number: "",
        school_year_id: "",
        parent_name: "",
        major_id: "",
      },
      modal2: false,
      teachers: []
    };
  },
  mounted() {
    // this.current_photo = this.currentUserPersonalInfo.photo;
    this.getTeacherOption()
    this.get();
  },
  methods: {
    async get() {
      this.form = await module.get("api/users/" + getUser().id);
      // If Data Not Found
      if (this.form == null) {
        // Redirect To List
        this.$router.push("/masters/users");
      } else {
        this.form["_method"] = "put";
        this.current_photo = this.form.photo;
        // this.dataLoaded = true
      }
    },
    async getTeacherOption() {
      let response = await module.setTreeSelect(
        "api/users?role_id=1&page_size=100"
      );
      // Success
      if (response.state == "success") {
        // Set Option Select
        this.teachers = response.data;
        this.teachers.unshift({
          label: "Pilih Dosen",
          id: "",
          isDisabled: true,
        });
      }
    },

    save() {
      var name = this.$refs.name.value;
      var surname = this.$refs.surname.value;
      var company_name = this.$refs.company_name.value;
      var phone = this.$refs.phone.value;
      var email = this.$refs.email.value;
      var company_site = this.$refs.company_site.value;
      var photo = this.photo;

      // set spinner to submit button
      const submitButton = this.$refs["kt_save_changes"];
      submitButton.classList.add("spinner", "spinner-light", "spinner-right");

      // dummy delay
      setTimeout(() => {
        // send update request
        this.$store.dispatch(UPDATE_PERSONAL_INFO, {
          name,
          surname,
          company_name,
          phone,
          email,
          company_site,
          photo,
        });

        submitButton.classList.remove(
          "spinner",
          "spinner-light",
          "spinner-right"
        );
      }, 2000);
    },
    cancel() {
      this.$refs.name.value = this.currentUserPersonalInfo.name;
      this.$refs.surname.value = this.currentUserPersonalInfo.surname;
      this.$refs.company_name.value = this.currentUserPersonalInfo.company_name;
      this.$refs.phone.value = this.currentUserPersonalInfo.phone;
      this.$refs.email.value = this.currentUserPersonalInfo.email;
      this.$refs.company_site.value = this.currentUserPersonalInfo.company_site;
      this.current_photo = this.currentUserPersonalInfo.photo;
    },
    onFileChange(e) {
      const file = e.target.files[0];

      if (typeof FileReader === "function") {
        const reader = new FileReader();

        this.form.photo = e.target.files[0];
        
        reader.onload = (event) => {
          this.current_photo = event.target.result;
        };

        reader.readAsDataURL(file);
      } else {
        alert("Sorry, FileReader API not supported");
      }
    },
    async formOnsubmit($event) {
      event.preventDefault()
      if (this.form.role_id != 2) {
        this.form.major_id = 0;
        this.form.school_year_id = 0;
      }
      this.form.grade_id = 0;
      this.form.school_id = 0;

      let formData = new FormData();
      for (const [key, value] of Object.entries(this.form)) {
        formData.append(key, value);
      }
      // Make Request
      let response = await module.submit(formData, `api/users/${getUser().id}`);
      // Check Response
      if (response.state == "error") {
        // Validation Error
        this.error = validation.setValidationError(
          this.error,
          response.error.errors
        );
      } else {
        // Success
        //('resp in here', response)
        Swal.fire(
          response.success.title,
          "Berhasil Mengedit Profil",
          "success"
        );
        // setUser(JSON.stringify(response.success.item.original))
        api.get("api/users/me").then((result) => {
          //('me', result)
          setUser(JSON.stringify(result.data.data));
        });
        this.$root.$emit("refreshCurrentUser");
        // this.$emit("successPost");
      }
    },
  },
  computed: {
    ...mapGetters(["currentUserPersonalInfo"]),
    photo() {
      return this.current_photo == null
        ? this.default_photo
        : this.current_photo;
    },
  },
};
</script>

<style scoped>
.image-input-wrapper {
  width: 150px !important;
  height: 150px !important;
}

.image-input-wrapper img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
</style>